import React, {useState, useEffect} from 'react'
import { accountBalance } from '../Request/Wallets';
const ChartBalance = () => {

    const [accBalance, setaccountBalance] = useState(accountBalance());
       const newaccountBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = parseFloat(data.account_balance);
        }
        return balance;
    }

    useEffect(() => {
        document.title = 'charts';
        if(window && window.init_template){
            window.init_template();
        } 

       window.addEventListener('updatedStorage', function (e) {
        setaccountBalance(newaccountBalance());
        
  }, false);
    }, []);

    const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
  return (
    <div className='container mb-4'>
        <div className='card shadow-sm color-theme' style={{borderRadius: '15px', padding: '50px'}}>
        <div className='text-center fw-bolder mb-3'>Account Balance</div>
        <h2 className='text-center font-36'>{getAmount(accBalance)}</h2>
        </div>
    </div>
  )
}

export default ChartBalance