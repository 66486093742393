import React, {useEffect} from 'react';
import { Link } from "react-router-dom";

const Forgot = () => {
    useEffect(() => {
        document.title = 'FORGOT PASSWORD';
      });
  return (
    <div className="page-content my-0 py-0">
        <div className="card bg-5 card-fixed">
            <div className="card-center mx-3 px-4 py-4 bg-white rounded-m">
                <div className="text-center d-flex justify-content-center mb-4">
                <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/w_204,h_58,c_fit/arbitragesmalldark.png" height="40" alt="logo"/>
                </div>
                <h1 className="font-30 font-800 mb-0 text-center text-dark">Recover Account</h1>
                <p className="text-center">Recover your Account</p>
                <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                    <i className="bi bi-at font-16"></i>
                    <input type="email" className="form-control text-dark rounded-xs" id="c1" placeholder="Email Address"/>
                    <label htmlFor="c1" className="text-dark">Email Address</label>
                    <span>(required)</span>
                </div>
                <Link to="/" className="btn btn-full gradient-green shadow-bg shadow-bg-s mt-4">Send Recovery Instructions</Link>
                <div className="row">
                    <div className="col-6 text-start">
                        <Link to="/register" className="font-14 color-green-light pt-4 d-block">Create Account</Link>
                    </div>
                    <div className="col-6 text-end">
                        <Link to="/login" className="font-14 color-green-light pt-4 d-block">Sign In Account</Link>
                    </div>
                </div>
            </div>
            <div style={{backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1662899092/yellowcardinalbanner.png)"}} className="card-overlay rounded-0 m-0 opacity-90 gradient-green">
            </div>
        </div>
    </div>
  )
}

export default Forgot