import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import {
  triggerSuccess,
  triggerError,
  setErrorMessage,
  setSuccessMessage,
} from '../Request/StatusFormatter';

const Register = () => {
   
  const [showP1, setShowP1] = useState(false);
  const [showP2, setShowP2] = useState(false);

  const resetp1 = () => {
    if (showP1 === false) {
      setShowP1(true)
    }else{
      setShowP1(false)
    }
  }

   const resetp2 = () => {
    if (showP2 === false) {
      setShowP2(true)
    }else{
      setShowP2(false)
    }
  }
  
  let inviter = localStorage.getItem('invited-by');
    const initialLogin = {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        phone: '',
        referral: ''
    }
   
    const [error, setErros] = useState('');
    const [ phoneNumber, setPhoneNumber] = useState();
    const [ country, setCountry ] =useState();
    const [registerForm, setRegisterForm] = useState(initialLogin);
    const onChange = (e) =>
    setRegisterForm({ ...registerForm, [e.target.name]: e.target.value });

    const { isLoading: isSendingRequest, mutate: postLogin } = useMutation(

        async () => {
          return await apiClient.post(`/api/register`, {
            name: registerForm.name,
            email: registerForm.email,
            password: registerForm.password,
            password_confirmation: registerForm.password_confirmation,
            phone: phoneNumber,
            country: country,
            referral: inviter            

          });
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            triggerSuccess();
            localStorage.setItem('auth-token', res.data.access_token);
             localStorage.setItem('delox-user', JSON.stringify(res.data.user));
            localStorage.setItem('plans', JSON.stringify(res.data.plans));
            localStorage.setItem('wallets', JSON.stringify(res.data.wallets));
            
            setTimeout(() => {
                window.location.href = '/dashboard';
            }, 3000);
          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErrorMessage(err.response?.data || err);  
            setErros(myerror.errors)       
            triggerError();
          },
        }
      );
      const onSubmit = async() =>{
        postLogin();
        // await apiClient.get("/sanctum/csrf-cookie").then(() => {
          
        // })
      }   
      
      useEffect(() => {
        document.title = 'REGISTER';
      });
  return (
    <div id="page-content" className="page-content my-0 pt-3 mt-8" style={{overflow: "auto !important"}}>
    <div className="card card-fixed bg-5 mt-8" style={{ marginTop: "30px"}}>
        <div className="card-center mx-3 px-4 py-4 bg-white rounded-m">
            <div className="text-center d-flex justify-content-center mb-2">
            <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/w_204,h_58,c_fit/arbitragesmalldark.png" height="40" alt="logo"/>
            </div>
            <h1 className="font-30 mb-0 text-center text-dark">Create Account</h1>
            <p className="text-center">Kindly fill the form below to create account</p>
            {(inviter !== null && inviter !== '') && <div className=" text-center text-info mt-n2">You were invited by  <strong>{inviter}</strong></div>}            
            <span className='text-danger'>{error !== '' && error.name}</span>
            <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">                
                <i className="text-dark material-icons-sharp" style={{fontSize: "17px"}}>account_circle</i>
                <input onChange={onChange} name="name" type="text" className="form-control text-dark rounded-xs" id="name" placeholder="Username"/>
                <label htmlFor="name" className="text-dark">Username</label>
            </div>
            
            <span className='text-danger'>{error !== '' && error.email}</span>
            <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
            <i className="text-dark material-icons-sharp" style={{fontSize: "17px"}}>email</i>
                <input onChange={onChange} name="email" type="email" className="form-control text-dark rounded-xs" id="email" placeholder="Email Address"/>
                <label htmlFor="email" className="text-dark">Email Address</label>
            </div>
            

            <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                <i className="text-dark material-icons-sharp" style={{fontSize: "17px"}}>password</i>
                <div className='d-flex justify-content-between'>
                <input onChange={onChange} name="password" type={showP1? 'text' : 'password'} className="form-control text-dark rounded-xs" id="password" placeholder="Choose Password"/>
                <label htmlFor="password" className="text-dark">enter Password</label>
                <div onClick={resetp1} className='border-none border-bottom-2' style={{transform: "translate(-30px, 0px)"}}>
                <i className="text-dark material-icons-sharp" style={{fontSize: "17px"}}>{showP1? 'remove_red_eye' : 'visibility_off'}</i>
                </div>
                </div>
                
            </div>

            <span className='text-danger'>{error !== '' && error.password}</span>
            <div className="form-custom form-label form-border form-icon mb-4 bg-transparent">
            <i className="text-dark material-icons-sharp" style={{fontSize: "17px"}}>password</i>
            <div className='d-flex justify-content-between'>
                <input onChange={onChange} name="password_confirmation" type={showP2? 'text' : 'password'} className="form-control text-dark rounded-xs" id="password_confirmation" placeholder="Confirm Password"/>
                <label htmlFor="password_confirmation" className="text-dark">Choose Password</label>

                <div onClick={resetp2} className='border-none border-bottom-2' style={{transform: "translate(-30px, 0px)"}}>
                <i className="text-dark material-icons-sharp" style={{fontSize: "17px"}}>{showP2? 'remove_red_eye' : 'visibility_off'}</i>
                </div>
            </div>
                
            </div>
            
            <span className='text-danger'>{error !== '' && error.country}</span><br/>
            <span className='text-danger'>{error !== '' && error.phone}</span>
            <div style={{border: 'none', borderBottom: "1px solid rgba(140, 193, 82, 0.3)"}} className="mb-4 bg-transparent d-flex "> 
              <PhoneInput
              placeholder="Enter phone number"
              defaultCountry="US"
              value={phoneNumber}
              onCountryChange={setCountry}
              onChange={setPhoneNumber}/>              
            </div>
            
            

            <div className="form-check form-check-custom">
                <input onChange={onChange} className="form-check-input" type="checkbox" name="type" value="" id="c2a"/>
                <label className="form-check-label font-14" htmlFor="c2a">I agree with the <a href="https://arbitrage-crests.com" className="color-green-light">Terms and Conditions</a>.</label>
                <i className="is-checked color-green-light font-13 bi bi-check-circle-fill"></i>
                <i className="is-unchecked color-green-light font-13 bi bi-circle"></i>
            </div>
            <div onClick={onSubmit} className="btn btn-full gradient-green mt-4">
              {isSendingRequest?<span>registering...</span>: <span>Create account</span>}
              </div>
            <div className="row">
                <div className="col-6 text-start">
                    <Link to="/forgot-password" className="font-14 color-green-light pt-4 d-block">Forgot Password?</Link>
                </div>
                <div className="col-6 text-end">
                    <Link to="/login" className="font-14 color-green-light pt-4 d-block">Sign In Account</Link>
                </div>
            </div>
        </div>
        <div style={{backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1662899092/yellowcardinalbanner.png)"}} className="card-overlay rounded-0 m-0 opacity-90 gradient-green">
            </div>
    </div>
</div>
  )
}

export default Register